import React from 'react';
import styled from 'styled-components';
import { Layout, Space } from 'antd';
import { HeaderButton, Logo } from '../common';
import { Link } from 'react-router-dom';

const Spacer = styled.div`
    flex: 1;
`;

const Links = styled(Space)`
    gap: 50px !important;

    @media (max-width: 800px) {
        gap: 20px !important;
    }
`;

const Toolbar = () => (
    <Layout.Header>
        <Link to="/">
            <Logo />
        </Link>

        <Spacer />

        <Links>
            <Link to="/stats">
                <HeaderButton type="link" size="large">
                    Setup Stats
                </HeaderButton>
            </Link>

            <Link to="/status">
                <HeaderButton type="link" size="large">
                    Status
                </HeaderButton>
            </Link>

            <a href="https://aleo.org/post/announcing-aleo-setup" target="_blank" rel="noreferrer">
                <HeaderButton type="link" size="large">
                    Blog
                </HeaderButton>
            </a>

            <Link to="/faq">
                <HeaderButton type="link" size="large">
                    FAQ
                </HeaderButton>
            </Link>
        </Links>
    </Layout.Header>
);

export default Toolbar;
